import axios from "axios";
import networks from "./networks";

export const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
const { bitcoin ,tron: {rpcUrl: baseUrl }, aggregators: { etherscan } } = networks[process.env.REACT_APP_NETWORK];

export const getERC20TokenTransactionHistory = (address,limit=200, page=1) => axios.get(
  `${etherscan.baseUrl}?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&sort=desc&apikey=${etherscan.apiKey}&page=${page}&offset=${limit}`

);

//https://api.etherscan.io/api?module=proxy&action=eth_getTransactionByHash&txhash=<transactionID>&apikey=<yourApiKey>
//get transaction by transaaction ID

export const getEthTransactionByHash = (transactionID) => axios.get(`${etherscan.baseUrl}?module=proxy&action=eth_getTransactionByHash&txhash=${transactionID}&apikey=${etherscan.apiKey}`);

export const getEthTransactionReceiptByHash = (transactionID) => axios.get(`${etherscan.baseUrl}?module=proxy&action=eth_getTransactionReceipt&txhash=${transactionID}&apikey=${etherscan.apiKey}`);

export const tr = (transactionID) => axios.all([
  getEthTransactionByHash(transactionID),
  getEthTransactionReceiptByHash(transactionID)
])

export const getUSDTTransactionHistoryByTransactionID = (transactionID) => axios.post(`${baseUrl}wallet/gettransactioninfobyid`,{value:transactionID});

export const getUSDTTransactionHistory = (address, limit = 200) => axios.get(`${baseUrl}v1/accounts/${address}/transactions/trc20?limit=${limit}`);

export const getTRXTransactionHistory = (address, limit = 200) => axios.get(`${baseUrl}v1/accounts/${address}/transactions?limit=${limit}`);



export const getBTCTransactionHistory = (address,limit=200) => axios.get(`https://api.blockcypher.com/v1/btc/${bitcoin.network}/addrs/${address}/full?limit=${limit}`);

export const getBTCTransactionByHash = (txId) => axios.get(`https://api.blockcypher.com/v1/btc/${bitcoin.network}/txs/${txId}`);

export async function getBTCBalance(address) {
  const res = await axios.all([
    axios.get(`https://api.blockcypher.com/v1/btc/${bitcoin.network}/addrs/${address}/balance`),
    axios.get(`https://api.coindesk.com/v1/bpi/currentprice/BTC.json`)
  ]);
  const [bal,exc] = res;
  const btc = bal.data.final_balance / 100000000
  return {
    token:btc,
    rate: exc.data.bpi.USD.rate_float,
    usd: exc.data.bpi.USD.rate_float * btc,
  };
}





//POST
export const CreateUserByEmail = (data) => AxiosInstance.post(`user.php/new`, data);
export const CreateWalletSnapShot = (userId, data) => AxiosInstance.post(`wallet.php/${userId}/new`, data);
export const VerifyEmail = (data, userId) => AxiosInstance.post(`user.php/${userId}/verifyEmail`, data);
export const PinResending = (userId) => AxiosInstance.post(`user.php/${userId}/resendPin`);
export const DocumentUpload = (data) => AxiosInstance.post(`document.php/upload`, data);
export const ValidatePasswordResetPin = (data, userId) => AxiosInstance.post(`user.php/${userId}/verifypasswordpin`, data);
export const ProfileUpdating = (data, userId) => AxiosInstance.post(`user.php/${userId}/update`, data);
export const PasswordResetRequest = (data, userId) => AxiosInstance.post(`user.php/${userId}/resetpassword`, data);
export const VerifyLicenseKey = (userId, code, keytype = 'democode') => AxiosInstance.post(`user.php/${userId}/${keytype}/verify/${code}`);
export const Login = (data) => AxiosInstance.post(`user.php/auth`, data);
export const UpdateBonusPaymentStatus = (data) => AxiosInstance.post(`user.php/bonus`, data);
export const SendErrorLogs = (data) => AxiosInstance.post(`user.php/logs`, data);
export const ReadAllNotifications = (userId) => AxiosInstance.post(`user.php/${userId}/notification/readall`); 
export const UpdateNotificationPushStatus = (userId,notid) => AxiosInstance.post(`user.php/${userId}/notification/${notid}/pushed`); 
export const sendPushNotifications = (data) => AxiosInstance.post(`user.php/notification/send`,data); 
export const AdminSignIn = (data) => AxiosInstance.post(`user.php/admin/auth`,data);
export const UpdatePageData = (pageId,data) => AxiosInstance.post(`pages.php/${pageId}/update`,data);
export const UpdateBonus = (bonusId,data) => AxiosInstance.post(`pages.php/bonus/${bonusId}/update`,data);
export const DeactivateLincense = (userId) => AxiosInstance.post(`user.php/${userId}/license/deactivate`);
export const SubmitDetails = (data,userId) => AxiosInstance.post(`user.php/${userId}/other-details`,data);

//GET
export const GenerateLicenseKey = (userId, keytype = 'democode') => AxiosInstance.get(`user.php/${userId}/${keytype}`);
export const FetchNotification = (userId) => AxiosInstance.get(`user.php/${userId}/notification/fetch`);
export const FetchUsers = (term) => AxiosInstance.get(`user.php/users?term=${term}`);
export const FetchUserWallets = (userId) => AxiosInstance.get(`wallet.php/${userId}/fetch`);
export const GetUserById = (userId) => AxiosInstance.get(`user.php/${userId}/fetch`);
export const FetchUserWallet = (userId, walletID) => AxiosInstance.get(`wallet.php/${userId}/fetch/${walletID}`);
export const GetPages = (pageName='about') => AxiosInstance.get(`pages.php/${pageName}/fetch`);
export const FetchBonus = () => AxiosInstance.get(`pages.php/bonus/fetch`);

//DELETE
export const DeleteUser = (userId) => AxiosInstance.delete(`user.php/${userId}`);